import React, { FC, useState } from "react";
import cn from "classnames";

import { ImageV2, Link } from "@src/components/ui";
import { IComponentNavigationPageLink } from "@src/types/page-components.types";
import { ReactComponent as GreyAccordionArrowDown } from "@assets/icons/grey-accordion-arrow-down.svg";
import { ReactComponent as GrayArrowRightSmall2 } from "@assets/icons/gray-arrow-right-small-2.svg";

type Props = {
	pageLinks: IComponentNavigationPageLink[];
	isOpen: boolean;
	onClose: () => void;
};

const Drawer: FC<Props> = (props) => {
	const { pageLinks, isOpen, onClose } = props;

	const [activeAccordion, setActiveAccordion] = useState<string | null>(null);

	const handleActiveAccordion = (key: string): void => {
		if (activeAccordion === key) return setActiveAccordion(null);
		setActiveAccordion(key);
	};

	const handleClose = (): void => {
		onClose();
		setActiveAccordion(null);
	};

	return (
		<div
			className={cn(
				"lg:hidden z-40 fixed top-[58px] left-0 w-screen h-screen pb-[60px] bg-white border-t-[0.5px] border-[#D6D7E6] transition-transform duration-200",
				{ "translate-y-0": isOpen },
				{ "-translate-y-full": !isOpen },
			)}
		>
			<div className="flex flex-col justify-between h-full overflow-y-auto">
				<ul className="my-8 mx-4">
					{pageLinks.map((pageLink) => {
						const { _key, link, subLinks } = pageLink;

						const isActive = activeAccordion === _key;
						const hasSubLinks = Boolean(subLinks.length);
						const accordionHeightClass = `h-[${subLinks.length * 48}px]`;

						return (
							<li
								key={_key}
								className="border-b-[0.5px] border-[#D6D7E6] last:border-none cursor-pointer"
								onClick={() => handleActiveAccordion(_key)}
							>
								<div className="flex items-center justify-between">
									<Link target={link.target} onClick={handleClose} className="flex-1">
										<p className="py-4 text-h4-mobile">{link.label}</p>
									</Link>
									{hasSubLinks ? (
										<div
											className={cn(
												"transition-transform duration-200",
												{ "-rotate-180": isActive },
												{ "rotate-0": !isActive },
											)}
										>
											<GreyAccordionArrowDown fill="#C3C4D8" />
										</div>
									) : (
										<GrayArrowRightSmall2 />
									)}
								</div>
								{hasSubLinks && (
									<ul
										className={`transition-all duration-200 overflow-hidden ${isActive ? `${accordionHeightClass} mt-8 opacity-100` : "h-0 mt-0 opacity-0 pointer-events-none"} `}
									>
										{subLinks.map((subLink) => {
											const { _key, label, target, icon } = subLink;

											return (
												<li key={_key} className="mb-6">
													<Link target={target} onClick={handleClose}>
														<div className="flex items-center">
															{icon && (
																<ImageV2 CMSImage={icon} alt={label + " icon"} className="mr-3" />
															)}
															<p>{label}</p>
														</div>
													</Link>
												</li>
											);
										})}
									</ul>
								)}
							</li>
						);
					})}
				</ul>
			</div>
		</div>
	);
};

export default Drawer;
