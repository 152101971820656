import React, { FC, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";

import { BurgerMenu, Drawer, Logo, PageLinks } from "./components";
import { CallToAction } from "@src/components/ui";
import { DEFAULT_LOCALE } from "@src/constants/i18n.constants";
import { INavigationData } from "./navigation.types";

const Navigation: FC = () => {
	const { allSanityNavigation } = useStaticQuery(graphql`
		{
			allSanityNavigation {
				nodes {
					language
					mobileLogo {
						asset {
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: WEBP
										quality: 75
										placeholder: BLURRED
										breakpoints: [480, 768, 1024, 1440]
										blurredOptions: { toFormat: WEBP }
										webpOptions: { quality: 75 }
									)
								}
								publicURL
								extension
							}
							width
							height
						}
					}
					desktopLogo {
						asset {
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: WEBP
										quality: 75
										placeholder: BLURRED
										breakpoints: [480, 768, 1024, 1440]
										blurredOptions: { toFormat: WEBP }
										webpOptions: { quality: 75 }
									)
								}
								publicURL
								extension
							}
							width
							height
						}
					}
					pageLinks {
						_key
						link {
							label
							target {
								_id
								slug
								parentPages {
									_id
									slug
								}
								title
								description
							}
						}
						subLinks {
							_key
							label
							target {
								_id
								slug
								parentPages {
									_id
									slug
								}
								title
								description
							}
							icon {
								asset {
									localFile {
										childImageSharp {
											gatsbyImageData(
												formats: WEBP
												quality: 75
												placeholder: BLURRED
												breakpoints: [480, 768, 1024, 1440]
												blurredOptions: { toFormat: WEBP }
												webpOptions: { quality: 75 }
											)
										}
										publicURL
										extension
									}
									width
									height
								}
							}
						}
					}
					callToAction {
						startIcon {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						label
						endIcon {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						target {
							_id
							slug
							parentPages {
								_id
								slug
							}
							title
							description
						}
						id
					}
				}
			}
		}
	`) as INavigationData;

	const navigation = allSanityNavigation.nodes.find((node) => node.language === DEFAULT_LOCALE);

	if (!navigation) return null;

	const { callToAction, mobileLogo, desktopLogo, pageLinks } = navigation;

	const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

	const handleBurgerMenuClick = () => setIsDrawerOpen((previousState) => !previousState);
	const handleCloseDrawer = () => setIsDrawerOpen(false);

	return (
		<>
			<nav className="sticky z-50 top-0 left-0 w-full bg-white">
				<div className="flex items-center justify-between max-w-screen-xl mx-auto py-3 lg:py-0 px-4 lg:px-14">
					<div className="flex-1">
						<Logo mobile={mobileLogo} desktop={desktopLogo} />
					</div>
					<div className="flex items-center">
						<PageLinks content={pageLinks} />
						<CallToAction
							CMSCallToAction={callToAction}
							className="flex items-center justify-center mr-3 lg:mr-0 lg:ml-12 px-5 py-2 lg:py-[14px] lg:px-16 transition-colors duration-200 bg-primary hover:bg-[#000096] rounded-full text-white text-center font-medium text-xs lg:text-sm"
						/>
						<BurgerMenu isOpen={isDrawerOpen} onClick={handleBurgerMenuClick} />
					</div>
				</div>
			</nav>
			<Drawer pageLinks={pageLinks} isOpen={isDrawerOpen} onClose={handleCloseDrawer} />
		</>
	);
};

export default Navigation;
